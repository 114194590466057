'use client'

import { useSearchParams } from 'next/navigation'
import { authenticate } from '@/app/lib/actions/auth'
import { LoadingButton } from '@/components/ui/Button'
import { useActionState } from 'react'

interface Props {
  classes?: {
    root?: string
    button?: string
  }
}

export const LoginButton = ({ classes }: Props) => {
  const searchParams = useSearchParams()
  const callbackUrl = searchParams.get('callbackUrl') ?? undefined
  const withCallbackUrl = callbackUrl ? authenticate.bind(null, callbackUrl) : authenticate
  const [errorMessage, _authenticate, isSigningIn] = useActionState(withCallbackUrl, undefined)

  return (
    <form action={_authenticate} className={classes?.root}>
      <LoadingButton type="submit" className={classes?.button} loading={isSigningIn} variant="outline">
        Sign In
      </LoadingButton>
    </form>
  )
}
